import React, { useState } from "react"
import Layout from "../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../components/main"
import Pricing from "../components/pricing"
import GuaranteeSection from "../components/guaranteesection"
import Features from "../components/features"
import Comparison from "../components/comparison"
import HowItWorks from "../components/howitworks"
import CustomerRating from "../components/customerrating"
import Testimonials from "../components/testimonials"

const Home = () => {
  const [subSection, setSubsection] = useState("home")
  const [productName, setProductName] = useState("likes")

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Boostik - Tiktok Analyzer Tool</title>
        <link rel="canonical" href="http://www.boostik.io/tiktok-agency" />
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="Boostik - Tiktok Agency"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Boostik - Tiktok Agency"
        />
        <meta
          property="og:description"
          content="Boostik - Tiktok Agency"
        />
        <meta property="og:url" content="https://www.boostik.io/tiktok-agency" />
        <meta property="og:site_name" content="Boostik" />
        <meta
          property="article:modified_time"
          content="2021-03-01T22:55:05+00:00"
        />
        <meta
          property="og:image"
          content="https://s3.eu-west-1.amazonaws.com/realboost.images/logo_black.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Main
        viewName="home"
        title="Track your social media performance with Boostik Analytics"
        description="Receive real-time insights on your social media performance with Boostik’s advanced analytics tool, seamlessly integrated into your dashboard"
      />
      <div style={{width:"100%", height: "150px"}}></div>
      <GuaranteeSection viewName={subSection} />
      <div className="section-container background-section-gray">
        <Features viewName="home" categoryName="home" />
      </div>
      <div>
        <Comparison viewName="home" categoryName="home" />
      </div>
      <div className="section-container background-section-gray">
        <CustomerRating viewName="home" categoryName="home" />
        <Testimonials
          categoryName="home"
          testimonialsList={[
            {
              name: "James Smith",
              review: 5,
              userImage: "user5",
              comment:
                "“The TikTok analyzer tool transformed our social media strategy. We now understand our audience better and create more engaging content”.",
            },
            {
              name: "Michael Wells",
              review: 5,
              userImage: "user20",
              comment:
                "“I never realized how much data could influence our TikTok success”.",
            },
            {
              name: "Charlotte Miller",
              review: 4,
              userImage: "user14",
              comment:
                "“The TikTok analyzer tool gives me detailed analytics, helping me to create content that resonates with my followers”.",
            },
            {
              name: "Daniel Davis",
              review: 4.5,
              userImage: "user12",
              comment:
                "“This tool is a game-changer! It helped us identify the best times to post, leading to higher engagement and more followers. Highly recommended for any serious TikTok user.”.",
            },
            {
              name: "Elijah Jones",
              review: 4.5,
              userImage: "user6",
              comment:
                "“The TikTok analyzer tool is incredibly user-friendly and packed with powerful features”.",
            },
            {
              name: "Ben Williams",
              review: 5,
              userImage: "user15",
              comment:
                "“Thanks to the Boostik, we've been able to pinpoint which types of content perform best”.",
            },
            {
              name: "Chloe Evans",
              review: 5,
              userImage: "user7",
              comment:
                "“I love how the tool breaks down complex data into easy-to-understand reports. It’s helped us optimize our content and significantly increase our reach”.",
            },
          ]}
        />
      </div>
    </Layout>
  )
}

export default Home
